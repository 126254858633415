import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import {Breadcrumb} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";


import FooterComponent from "../components/footer";

const AboutUs = () => {
  return (
    <div>
      <Container>
        <Row className="m-auto justify-content-center">
          <Breadcrumb className="mt-5">
            <Breadcrumb.Item className="text-white">
              <Link to="/" className="text-decoration-none text-white-50">
                Home
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item className="text-white" active>About us</Breadcrumb.Item>
          </Breadcrumb>
          <Col md={12} className="d-flex justify-content-center">
            <Col md={6} className="justify-content-center">
              <Image
                src="./assets/img/logo-blue.png"
                alt=""
                fluid
                className="d-block"
              />
            </Col>
          </Col>
          <Col md={12} className="cus-dark br-15">
            <p className="josefin-sans text-white p-3 lh-base fs-16 mt-2 text-justify">
              {/* Your content */}

              The Buzz in a statement is - literally just what it’s tag line
              says – Voice of CHRIST Lavasa. Initiated by students of CHRIST
              (Deemed to be University) Lavasa, this will feature a wide array
              of information – ranging from Fun to Knowledge, Uniqueness to
              Creativity. Daily campus routine witnesses a range of expressions
              and we at The Buzz try to capture it all. Music based shows, Book
              reviews, Poetry, Interviews Talks and Gyaan – all on one single
              platform.
            </p>
          </Col>
          <Col md={12} className="mt-5">
            <Row>
              <Col md={12} className="mb-4">
                <h2 className="text-center mt-2 fw-bold mb-3 fs-5 text-white">
                  Design & Creative Core Team
                </h2>
                <Row className="align-items-center justify-content-center">
                  {/* Repeat the following block for each team member */}
                  <Col
                    md={4}
                    className="d-flex justify-content-center align-items-center mt-2 flex-column"
                  >
                    <Image
                      src="./assets/images/user.png"
                      alt=""
                      fluid
                      className="w-50 rounded-circle br-15"
                    />
                    <h4 className="text-center mt-2 d-block fs-14 text-white josefin-sans">
                      Alanso Mathew
                    </h4>
                  </Col>
                  <Col
                    md={4}
                    className="d-flex justify-content-center align-items-center mt-2 flex-column"
                  >
                    <Image
                      src="./assets/images/user.png"
                      alt=""
                      fluid
                      className="w-50 rounded-circle br-15"
                    />
                    <h4 className="text-center mt-2 d-block fs-14 text-white josefin-sans">
                      Alanso Mathew
                    </h4>
                  </Col>
                  <Col
                    md={4}
                    className="d-flex justify-content-center align-items-center mt-2 flex-column"
                  >
                    <Image
                      src="./assets/images/user.png"
                      alt=""
                      fluid
                      className="w-50 rounded-circle br-15"
                    />
                    <h4 className="text-center mt-2 d-block fs-14 text-white josefin-sans">
                      Alanso Mathew
                    </h4>
                  </Col>
                  {/* End of team member block */}
                  {/* Repeat the following block for each team member */}
                  {/* ... */}
                </Row>
              </Col>
              <h2 className="text-center mt-2 fw-bold mb-3 fs-5 text-white">
                Category Co-ordinators
              </h2>
              <Col md={6} className="mb-4">
                <h4 className="text-center mt-2 fw-bold mb-3 fs-6 text-white">
                  Musical Lighthouse
                </h4>
                <Row className="align-items-center justify-content-center">
                  {/* Repeat the following block for each team member */}
                  <Col
                    md={6}
                    className="d-flex justify-content-center align-items-center mt-2 flex-column"
                  >
                    <Image
                      src="./assets/images/user.png"
                      alt=""
                      fluid
                      className="w-50 rounded-circle br-15"
                    />
                    <h4 className="text-center mt-2 d-block fs-14 text-white josefin-sans">
                      Alanso Mathew
                    </h4>
                  </Col>
                  <Col
                    md={6}
                    className="d-flex justify-content-center align-items-center mt-2 flex-column"
                  >
                    <Image
                      src="./assets/images/user.png"
                      alt=""
                      fluid
                      className="w-50 rounded-circle br-15"
                    />
                    <h4 className="text-center mt-2 d-block fs-14 text-white josefin-sans">
                      Alanso Mathew
                    </h4>
                  </Col>
                </Row>
              </Col>
              <Col md={6} className="mb-4">
                <h4 className="text-center mt-2 fw-bold mb-3 fs-6 text-white">
                  The Talk of the Week
                </h4>
                <Row className="align-items-center justify-content-center">
                <Col
                    md={6}
                    className="d-flex justify-content-center align-items-center mt-2 flex-column"
                  >
                    <Image
                      src="./assets/images/user.png"
                      alt=""
                      fluid
                      className="w-50 rounded-circle br-15"
                    />
                    <h4 className="text-center mt-2 d-block fs-14 text-white josefin-sans">
                      Alanso Mathew
                    </h4>
                  </Col>
                  <Col
                    md={6}
                    className="d-flex justify-content-center align-items-center mt-2 flex-column"
                  >
                    <Image
                      src="./assets/images/user.png"
                      alt=""
                      fluid
                      className="w-50 rounded-circle br-15"
                    />
                    <h4 className="text-center mt-2 d-block fs-14 text-white josefin-sans">
                      Alanso Mathew
                    </h4>
                  </Col>
                </Row>
              </Col>
              {/* Add more sections for Category Co-ordinators */}
            </Row>
          </Col>
        </Row>
      </Container>
      <div className="container-fluid">
        {/* Footer */}
        <FooterComponent />
      </div>
    </div>
  );
};

export default AboutUs;
