import { TransitionGroup, CSSTransition } from "react-transition-group";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { Container, Row } from "react-bootstrap";
import { fetchCategories } from "../redux/features/playerSlice";
import HeaderComponent from "../components/navbar";
import CategoryItem from "../components/category";
import PodcastComponent from "../components/bannerSmall";
import MusicList from "../components/musicList";
import FooterComponent from "../components/footer";
import SidbarFull from "../components/sidbarFull";
import JoinUsBanner from "../components/banner";
import MusicPlayer from "../components/MusicPlayer/index";

const Home = () => {
  const dispatch = useDispatch();
  const location = useLocation(); // Get the current location for transitions
  const navigate = useNavigate();
  const { activeSong } = useSelector((state) => state.player);
  const categories = useSelector((state) => state.player.categories);
  const imageUrl = "assets/images/thubmnail-podcast.png";

  const [loading, setLoading] = useState(true); // State to manage loading
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);

  // Fetch categories on component mount
  useEffect(() => {
    dispatch(fetchCategories())
      .finally(() => setLoading(false)); // Stop loading once categories are fetched
  }, [dispatch]);

  const handleCategoryClick = (categoryId) => {
    setSelectedCategoryId(categoryId);
    navigate(`/category/${categoryId}`);
  };

  return (
    <div className="container-fluid w-100">
      <div className="row w-100">
        <div className="col-md-3 col-sm-6 col-xs-12">
          <SidbarFull />
        </div>
        <div className="col-md-9">
          <HeaderComponent />
          <TransitionGroup>
            <CSSTransition
            in={true}
              key={location.pathname} // Ensure transitions are tied to route changes
              classNames="page"
              timeout={300} // Match this with the CSS animation timing
              unmountOnExit
            >
              <Routes location={location}>
                <Route
                  path="*"
                  element={
                    <div>
                      {!loading ? (
                        <>
                          <JoinUsBanner imageUrl={imageUrl} />
                          <Row>
                            {categories.map((category) => (
                              <CategoryItem
                                item={category}
                                key={category.id}
                                onClick={() => handleCategoryClick(category.id)}
                              />
                            ))}
                          </Row>
                        </>
                      ) : (
                        <p>Loading...</p>
                      )}
                    </div>
                  }
                />
                <Route
                  path="/category/:selectedCategoryId"
                  element={
                    <div className="row mt-4">
                      <PodcastComponent />
                      <Row className="mt-4">
                        <MusicList />
                      </Row>
                    </div>
                  }
                />
              </Routes>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
      <FooterComponent />
      {activeSong?.name && <MusicPlayer />}
    </div>
  );
};

export default Home;
