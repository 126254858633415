// src/components/RecentUpdatesComponent.js

// This component renders a list of recently updated music tracks.
// It fetches the music tracks from the API and displays them on the page.
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import { getAllCategorys } from "../api";
import {
  playPause,
  setActiveSong,
  setLatest,
} from "../redux/features/playerSlice"; // Import the setLatest action
import PlayPause from "./PlayPause";
import LatestSongItem from "./LatestSongItem";

const RecentUpdatesComponent = () => {
  const dispatch = useDispatch();
  const { activeSong, isPlaying } = useSelector((state) => state.player);

  useEffect(() => {
    // Fetch latest songs and update Redux store
    getAllCategorys()
      .then((res) => {
        const latestSongs = res.latest;
        dispatch(setLatest(latestSongs));
      })
      .catch((error) => {
        console.error("Error fetching latest songs:", error);
      });
  }, [dispatch]);

  const latestSongs = useSelector((state) => state.player.latest);
  const first10LatestSongs = latestSongs.slice(0, 10);

  return (
    <Container fluid className="mt-4">
      <Row>
        <Col md={12} >
          <div className="d-md-flex d-none p-2 mx-1 flex-column cus-dark align-items-start br-15">
            <Row className="mb-1">
              <Col md={12} className="mx-4 cus-nav">
                <Row className="d-flex justify-content-center w-100">
                  <Col xs={2}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      {/* ... SVG Path ... */}
                      <g clipPath="url(#clip0_1_323)">
                      <path
                        d="M19.1667 0H18.3333C16.0358 0 14.1667 1.86917 14.1667 4.16667V11.8842C13.3733 11.2283 12.3567 10.8333 11.25 10.8333C8.72333 10.8333 6.66667 12.8892 6.66667 15.4167C6.66667 17.9442 8.72333 20 11.25 20C13.7767 20 15.8333 17.9442 15.8333 15.4167V4.16667C15.8333 2.78833 16.955 1.66667 18.3333 1.66667H19.1667C19.6275 1.66667 20 1.29333 20 0.833333C20 0.373333 19.6275 0 19.1667 0ZM11.25 18.3333C9.64167 18.3333 8.33333 17.025 8.33333 15.4167C8.33333 13.8083 9.64167 12.5 11.25 12.5C12.8583 12.5 14.1667 13.8083 14.1667 15.4167C14.1667 17.025 12.8583 18.3333 11.25 18.3333ZM0 0.833333C0 0.373333 0.3725 0 0.833333 0H10C10.4608 0 10.8333 0.373333 10.8333 0.833333C10.8333 1.29333 10.4608 1.66667 10 1.66667H0.833333C0.3725 1.66667 0 1.29333 0 0.833333ZM0 5.83333C0 5.37333 0.3725 5 0.833333 5H10C10.4608 5 10.8333 5.37333 10.8333 5.83333C10.8333 6.29333 10.4608 6.66667 10 6.66667H0.833333C0.3725 6.66667 0 6.29333 0 5.83333ZM6.66667 10.8333C6.66667 11.2933 6.29417 11.6667 5.83333 11.6667H0.833333C0.3725 11.6667 0 11.2933 0 10.8333C0 10.3733 0.3725 10 0.833333 10H5.83333C6.29417 10 6.66667 10.3733 6.66667 10.8333Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1_323">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                    </svg>
                  </Col>
                  <Col xs={10} className="px-2">
                    <div className="text-white varela-round">Recent Updates</div>
                  </Col>
                </Row>
              </Col>
            </Row>
            {first10LatestSongs.map((song, i) => (
              <LatestSongItem
                key={song.id}
                song={song}
                isPlaying={isPlaying}
                activeSong={activeSong}
                data={latestSongs}
                i={i}
              />
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default RecentUpdatesComponent;

     