import React from 'react';
import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';

const SidebarComponent = () => {
    return (
        <Container fluid className="mt-4 d-none d-md-block">
          <Nav className="flex-column cus-dark p-2 mx-1 br-15">
            <Nav.Item className='mx-2'>
              <Nav.Link as={Link} to="/" className="text-white d-flex align-items-center gap-4">
                <i className="fa fa-home" aria-hidden="true"></i>
                Home
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className='mx-2'>
              <Nav.Link as={Link} to="/category" className="text-white d-flex align-items-center gap-4">
                <i className="fa fa-fire" aria-hidden="true"></i>
                Category
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Container>
      );
      
}

export default SidebarComponent;
