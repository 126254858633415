import axios from "axios";

const baseURL = "https://voiceoflavasaserver.christuniversity.in/";

export const getAllCategorys = async () => {
  try {
    const res = await axios.get(`${baseURL}api/play/`);
    // console.log(res.data);
    return res.data;
  } catch (error) {
    return null;
  }
};

export const getSongsByCategory = async (categoryId) => {
  try {
    const res = await axios.get(`${baseURL}api/play/${categoryId}/`);
    return res.data;
  } catch (error) {
    return null;
  }
};

export const getBanner = async () => {
  try {
    const res = await axios.get(`${baseURL}api/banner/`);
    return res.data;
  } catch (error) {
    return null;
  }
};

export const addSub = async (form) => {
  try {
    const res = await axios.post(`${baseURL}api/add/sub/`, form);
    return res.data;
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
};

export const getSongs = async (songId) => {
  try {
    const res = await axios.get(`${baseURL}api/music/${songId}/`);
    return res.data;
  } catch (error) {
    return null;
  }
};

export const findSongs = async (searchValue) => {
  try {
    const res = await axios.get(`${baseURL}api/search/${searchValue}/`);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const findCategory = async (categoryId) => {
  try {
    const res = await axios.get(`${baseURL}api/category/${categoryId}/`);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const findArtists = async (songId) => {
  try {
    const res = await axios.get(`${baseURL}api/artist/${songId}/`);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const sendCount = async (songId) => {
  try {
    const res = await axios.get(`${baseURL}api/media/${songId}/`);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};


