// src/components/MusicList.js

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { playPause, setActiveSong } from "../redux/features/playerSlice";
import { getSongsByCategory } from "../api";
import { useParams } from "react-router-dom";
import PlayPause from "./PlayPause";
import PORT from "../const";
import MusicListItem from "./MusicListItem";

const MusicList = () => {
  // Define your Redux state variables and dispatch
  const dispatch = useDispatch();
  const isPlaying = useSelector((state) => state.player.isPlaying);
  const activeSong = useSelector((state) => state.player.activeSong);
  const [musicObj, setMusicObj] = useState([]);
  const { selectedCategoryId } = useParams();

  useEffect(() => {
    // Fetch songs based on the category ID from the URL
    fetchSongs(selectedCategoryId);
  }, [selectedCategoryId]);

  const fetchSongs = async (categoryId) => {
    try {
      const response = await getSongsByCategory(categoryId);
      if (response && response.musicObj) {
        // console.log(response.musicObj);
        setMusicObj(response.musicObj);
      }
    } catch (error) {
      console.error("Error fetching songs:", error);
    }
  };
  

  return (
    <div className="col-md-12 mt-3 align-items-center">
      <div className="row m-auto align-items-center justify-content-between play-text p-2 br-color">
        <div className="col-1">
          <small></small>
        </div>
        <div className="col-4">
          <small>Title</small>
        </div>
        <div className="col-4">Creator</div>
        <div className="col-1 d-none d-md-block">
          <i className="fa-regular fa-circle-play"></i>
        </div>
        <div className="col-1 d-none d-md-block">
          <i className="fa-regular fa-clock"></i>
        </div>
        <div className="col-1 d-none d-md-block"></div>
      </div>
{musicObj &&
        musicObj.map((item, index) => (
          <MusicListItem
          key={item.id}
          song={item}
          isPlaying={isPlaying}
          activeSong={activeSong}
          data={musicObj}
          i={index}
          />
        ))}
    </div>
  );
};

export default MusicList;
